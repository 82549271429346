import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// _mock
import { _socials } from '../../_mock/arrays';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';

// config
import { BRAND } from '../../config-global';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: BRAND.name,
    children: [
      { name: 'About Us', href: PATH_PAGE.about },
      { name: 'Contact Us', href: PATH_PAGE.contact },
    ],
  },

  {
    headline: 'Contact',
    children: [
      { name: 'contact@alphwood.com', href: 'mailto:contact@alphwood.com' },
      {
        name: 'C1208, Iconic Tower, The Corenthum, Sector 62, Noida, UP 201309',
        href: 'https://maps.app.goo.gl/DXGtYJKQVTTEdY376',
      },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 }, mb: 3 }}>
              Interior.The way you want it to be
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link key={link.name} href={link.href} color="inherit" variant="body2">
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © 2024 DEECTRA PRIVATE LIMITED. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return mainFooter;
}
