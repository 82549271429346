export const GA = {
  EVENT_NAME: {
    get_quotation_btn: 'get_quotation_btn_clk',
    see_prices_btn: 'see_prices_btn_clk',
    get_free_estimate_btn: 'get_free_estimate_btn_clk',
    filter_change_btn: 'filter_change_btn_clk',
    scroll_up_filters_btn: 'scroll_up_filters_btn_clk',
    scroll_down_filters_btn: 'scroll_down_filters_btn_clk',
    view_more_btn: 'view_more_btn_clk',
    help_support_btn: 'help_support_btn_clk',
    book_home_visit_btn: 'book_home_visit_btn_clk',
    alphwood_shop_btn: 'alphwood_shop_btn_clk',
  },
  CATEGORY: {
    button_click: 'button_click',
    card_click: 'card_click',
    page_viewed: 'page_viewed',
  },
};
