import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyACIcOJ3aUSyK857S380skaUfcPA4pvfm8',
  authDomain: 'alphwood-be73a.firebaseapp.com',
  projectId: 'alphwood-be73a',
  storageBucket: 'alphwood-be73a.firebasestorage.app',
  messagingSenderId: '631206649311',
  appId: '1:631206649311:web:5b71b2179b5db654a9f86a',
  measurementId: 'G-YLLCH3Q2BC',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { analytics };
