import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { List, Drawer, IconButton } from '@mui/material';
// config
import { NAV } from '../../../../config-global';
// components
import Logo from '../../../../components/logo';
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
//
import NavList from './NavList';
import useAnalytics from '../../../../hooks/useAnalytics';
import { GA } from '../../../../constants/ga-events';

// ----------------------------------------------------------------------

NavMobile.propTypes = {
  data: PropTypes.array,
  isOffset: PropTypes.bool,
};

export default function NavMobile({ isOffset, data }) {
  const { pathname } = useLocation();
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleScrollToCatalog = () => {
    try {
      trackEvent(GA.EVENT_NAME.scroll_down_filters_btn, {
        category: GA.CATEGORY.button_click,
      });
      const element = document.getElementById('filters');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        navigate('/');
      }
    } catch (error) {
      //
    }
  };

  const handleOpenShop = () => {
    trackEvent(GA.EVENT_NAME.alphwood_shop_btn, {
      category: GA.CATEGORY.button_click,
    });
    window.open('https://shop.alphwood.com/home', '_blank');
  };

  return (
    <>
      <IconButton
        onClick={handleScrollToCatalog}
        sx={{
          ml: 1,
          ...(isOffset && {
            color: 'text.primary',
          }),
        }}
      >
        <Iconify icon="flat-color-icons:gallery" />
      </IconButton>
      <IconButton
        onClick={handleOpenShop}
        sx={{
          ml: 1,
          ...(isOffset && {
            color: 'text.primary',
          }),
        }}
      >
        <Iconify icon="flat-color-icons:shop" />
      </IconButton>
      <IconButton
        onClick={handleOpen}
        sx={{
          ml: 1,
          ...(isOffset && {
            color: 'text.primary',
          }),
        }}
      >
        <Iconify icon="eva:menu-2-fill" />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List component="nav" disablePadding>
            {data.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}
